import React from 'react'
import {
  Subheading,
  Card,
  FormLayout,
  TextField,
  TextStyle,
  TextContainer,
} from "@shopify/polaris"
import styled from 'styled-components'
import update from 'immutability-helper'
import ManualRules from './../manualRules'
import ShopBrain from '../../checkout/shopBrain'

const CartOfferTypeWrapper = styled.div`
    margin-bottom: 20px;
    .rule-type {
        padding-top: 1.6rem;
        .rule-type-subtitle {
            margin: 0px 0 20px;
        }
        .rule-type-option {
            border: 2px solid #AAA;
            border-radius: 4px;
            padding: 10px 10px;
            margin-bottom: 25px;
            .rule-type-image {
                text-align: center;
                img {
                    width: 85px;
                }
            }
            .rule-type-image:nth-of-type(2) {
                img {
                    width: 96px;
                }
            }
            .rule-type-desp {
                max-width: 210px;
            }
        }
        .active {
            border: 2px solid #008060;
        }
    }
`
function CartOfferType(props) {

  const { host, token, checkoutOfferType, shop, addSmartRule, state, setState, productPickerShow, setProductPickerShow, error, productEdit, setProductEdit, currency, currencySymbol } = props

  return (
    <CartOfferTypeWrapper>
      <Card sectioned>
        <FormLayout>
          <Subheading>Name Your upsell</Subheading>
          <TextField
            label="Internal offer nickname"
            placeholder="Your shoppers won’t see this."
            labelHidden={true}
            onChange={(val) => {
              setState(update(state, { name: { $set: val }}))
            }}
            value={state.name}
          />
          {state.id ?
            <TextContainer spacing="tight">
              <TextStyle variation="subdued">Offer ID: {state.id}</TextStyle>
            </TextContainer>
            :
            false
          }
        </FormLayout>
      </Card>
      {"manual"===checkoutOfferType ?
        <ManualRules host={host} shop={shop} token={token} addSmartRule={addSmartRule} state={state} setState={setState} productPickerShow={productPickerShow} setProductPickerShow={setProductPickerShow} error={error} productEdit={productEdit} setProductEdit={setProductEdit} currency={currency} currencySymbol={currencySymbol}/>
        :
        <ShopBrain host={host} shop={shop} state={state} token={token} setState={setState} productPickerShow={productPickerShow} setProductPickerShow={setProductPickerShow}/>
      }

    </CartOfferTypeWrapper>
  )
}

export default CartOfferType;